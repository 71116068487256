import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

export default function Arrow(props) {
  return (
    <button
      type="button"
      {...omit(props, ['currentSlide', 'slideCount', 'markup'])}
    >
      {props.markup}
    </button>
  );
}

Arrow.propTypes = {
  markup: PropTypes.element.isRequired
};
